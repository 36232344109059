import axios from 'axios'
import { addDocuments, getDocuments } from 'common/apis/maindata'
import { DocumentList } from 'components/DocumentList'
import React, { useState, useEffect } from 'react'

export default function Documents({  }) {
  const [directoryTree, setDirectoryTree] = useState([])
  const initDirectories = () => {
    const tree = [
      {
        title: '微信文档',
        key: 'wx',
        path: '/',
        children: [
          { title: '服务业务', key: '服务业务', path: `/服务业务`, children: [] },
          { title: '产品技术资料', key: '产品技术资料', path: `/产品技术资料`, children: [] },
        ],
      },
    ]
    setDirectoryTree(tree)
    console.log(tree);
  }
  useEffect(() => {
    initDirectories()
  }, [])

  return (
    <div style={{ height: '100%' }}>
      <div className='widget'>
        <div className='widget-header'>
          <div className='widget-title'>微信文档</div>
        </div>
        <div className='widget-body device-profile-details'>
          <DocumentList
            basePath={'/_/wx'}
            directory={directoryTree}
            onCreatedDirectory={({ action, item, data }) => {
              console.log(data)
            }}
          ></DocumentList>
        </div>
      </div>
    </div>
  )
}
