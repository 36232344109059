import React, { useState, useEffect, useRef } from 'react'
// import { WithTooltip, Tooltip, Icon, Button } from "@abb/abb-common-ux-react"
import { Button, Tag } from '@abb/abb-common-ux-react'
// import { getUserProfile } from "slices/userProfileSlice"
import { deleteDeviceType, deleteMetaData, getDeviceMetaDatas } from 'common/apis/maindata'
// import { useSelector } from "react-redux"
import axios from 'axios'
import { CommonConfirm } from 'components/LoongInCommon'
import { showNotification, NotificationType } from 'components/Notification/notificationSlice'
import { CommonIndex } from 'components/LoongInCommon'
import { DeviceTypeFormEdit } from './DeviceTypeFormEdit'
import { MetaDataFormEdit } from './MetaDataFormEdit'
import { LeftPanel } from './LeftPanel'

import moment from 'moment'

import { useDispatch } from 'react-redux'
import { t } from 'i18next'
import { LifeCycleModal } from './LifeCycleModal'

export default function DeviceTypes() {
  // const [isOperating, setIsOperating] = useState(false)
  const [deviceTypeFormData, setDeviceTypeFormData] = useState(null)
  const [activeItem, setActiveItem] = useState(null)
  const [activDataIndex, setActivDataIndex] = useState('')
  const [deleteId, setDeleteId] = useState(null)
  const [isDeleting, setIsDeleting] = useState(false)
  const dispatch = useDispatch()
  const formDevTypRef = useRef(null)
  const refIndex = useRef(null)
  const devTypRef = useRef(null)
  const lifeCycleRef = useRef(null)
  const [searchData, setSearchData] = useState(null)
  const options = [
    { label: '短文本', value: '1' },
    { label: '长文本', value: '2' },
    { label: '数值', value: '3' },
    { label: '是否', value: '4' },
    { label: '枚举', value: '5' },
    { label: '日期', value: '6' },
  ]
  //列定义
  const columns = [
    {
      title: 'metaData.code',
      key: 'code',
    },
    {
      title: 'metaData.name',
      key: 'name',
    },
    {
      title: 'metaData.dataType',
      key: 'dataType',
      render: (item, index) => {
        return options.find((o) => o.value == item.dataType)?.label
      },
    },
    {
      title: 'metaData.attributes',
      key: 'attributes',
      render: (item, index) => {
        return item.attributes?.showInReport ? <Tag type='success'>显示在报告中</Tag>:<></>
      },
    },
  ]
  const onAddBefore = () => {
    console.log(activeItem)
    return {
      deviceTypeId: activeItem.key,
      deviceTypeName: activeItem.title,
    }
  }
  const toolChildren = (
    <>
      <Button
        text={t('b_addDeviceTypeRoot')}
        type='normal'
        sizeClass='small'
        icon={'abb/plus'}
        // isLoading={isOperating}
        onClick={() => {
          setDeviceTypeFormData({
            name: '',
          })
          setShowOrganSidePanel(true)
        }}
      />
    </>
  )
  const setShowOrganSidePanel = (visible) => {
    formDevTypRef.current.setShowSidePanel(visible)
  }
  const onSubmit = (d) => {
    if (activDataIndex) {
      setActivDataIndex('')
      setDeviceTypeFormData(null)
      devTypRef.current.getDeviceTypesTree()
    } else {
      devTypRef.current.getDeviceTypesTree()
    }
  }
  const leftPanel = (
    <LeftPanel
      ref={devTypRef}
      onActive={(item) => {
        setActiveItem(item)
        setSearchData({ deviceTypeId: item.key })
        // refIndex.current.query()
      }}
      onAdd={(item) => {
        setDeviceTypeFormData({
          parentId: item.key,
          parentName: item.title,
          name: '',
        })
        setActivDataIndex('')
        setShowOrganSidePanel(true)
      }}
      onEdit={(item) => {
        setDeviceTypeFormData({
          id: item.key,
          parentName: '',
          name: item.title,
          upgradeScore:item.upgradeScore,
          upgradeCycle:item.upgradeCycle,
          type: item.type,
          picture: item.picture,
          lifeCycleConfig: item.lifeCycleConfig,
          productStage: item.productStage,
        })
        setActivDataIndex(item.key)
        setShowOrganSidePanel(true)
      }}
      onConfigLifeCycle={(item) => {
        lifeCycleRef.current.showDialog(item)
      }}
      onDel={(item) => {
        setDeleteId(item.key)
      }}
    ></LeftPanel>
  )
  const onDeleteConfirm = async () => {
    try {
      setIsDeleting(true)
      const response = await axios.post(deleteDeviceType(), { id: deleteId })
      if (response.status === 200 || response.status === 204) {
        setActivDataIndex('')
        setDeviceTypeFormData(null)
        setDeleteId(null)
        devTypRef.current.getDeviceTypesTree()
        dispatch(showNotification(NotificationType.Success, t('t_hasDelete')))
      } else {
        dispatch(showNotification(NotificationType.Warn, t('t_deleteFail')))
      }
    } catch (e) {
      dispatch(showNotification(NotificationType.Alarm, t('t_deleteFail')))
    } finally {
      setIsDeleting(false)
    }
  }
  //返回页面
  return (
    <>
      <CommonIndex
        ref={refIndex}
        columns={columns}
        getList={getDeviceMetaDatas}
        searchData={searchData}
        deleteData={deleteMetaData}
        toolChildren={toolChildren}
        FormEdit={MetaDataFormEdit}
        canAdd
        canEdit
        canDelete
        tools={{ add: { text: t('b_addMetaData'), disabled: !activeItem } }}
        onAddBefore={onAddBefore}
        refreshAfterDelete={true}
        enableSorting={false}
        leftPanelWidth={400}
        leftPanel={leftPanel}
        refreshWhenOpen={false}
        canSelection={false} //是否开启选择功能
        canBatchDel={false}
        searchClassName='searching-form-simple' //查询样式
        toolClassName='searching-form-simple searching-form-right-tool text-right' //工具栏样式
      />
      <DeviceTypeFormEdit
        ref={formDevTypRef}
        formData={deviceTypeFormData}
        onCancel={() => {
          setActivDataIndex('')
          setDeviceTypeFormData(null)
        }}
        id={activDataIndex}
        onSubmit={() => setTimeout(() => onSubmit(), 500)}
      />
      <CommonConfirm
        title={t('t_titleDeleteData')}
        content={t('t_confirmDeleteData')}
        isVisible={deleteId != null}
        onConfirm={onDeleteConfirm}
        confirmText={t('b_confirm')}
        onCancel={() => setDeleteId(null)}
        isLoading={isDeleting}
      />
      <LifeCycleModal ref={lifeCycleRef} onRefresh={() => setTimeout(() => onSubmit(), 500)}></LifeCycleModal>
    </>
  )
}
