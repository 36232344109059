import React, { useState, useRef } from 'react'
import { getUserProfile } from 'slices/userProfileSlice'
import { useSelector } from 'react-redux'
import axios from 'axios'
// import { CommonConfirm } from "components/LoongInCommon"
import { CommonConfirm, CommonIndex } from 'components/LoongInCommon'
import moment from 'moment'
import { getEnv } from 'slices/envSlice'

import { useDispatch } from 'react-redux'
// import { ResponseResult } from "common/request"
import { t } from 'i18next'
import { generateTotalReport, getTotalReportPage } from 'common/apis/totalreports'
import { nanoid } from 'nanoid'
import { getPermissions } from 'slices/abpApplicationConfigurationSlice'
import { getUsers } from 'slices/userSlice'
import { downloadFile } from 'common/apis/maindata'
import { ShowTotalReport } from './ShowTotalReport'
import { EditTotalReport } from './EditTotalReport'
import { ApproveTotalReport } from './ApproveTotalReport'
import { ImportTestResultFormEdit } from './ImportTestResultFormEdit'
import { BackTotalReport } from './BackTotalReport'

export default function Reports() {
  const env = useSelector(getEnv)
  const userProfile = useSelector(getUserProfile)
  const [totalReportGenerate, setTotalReportGenerate] = useState(null)
 
  const [isTotalGenerating, setIsTotalGenerating] = useState(false)
  const permissions = useSelector(getPermissions)
  const dispatch = useDispatch()
  const refIndex = useRef(null)
  const totalPreviewRef = useRef(null)
  const totalEditwRef = useRef(null)
  const totalApproveRef = useRef(null)
  const totalBackRef = useRef(null)
  const formDeviceTypeRef = useRef(null)
  const users = useSelector(getUsers)

  const totalStatus = [
    { key: 0, title: '待编辑' },
    { key: 1, title: '待审核' },
    { key: -1, title: '已驳回' },
    { key: 2, title: '待生成' },
    { key: 3, title: '生成中' },
    { key: 4, title: '已生成' },
    { key: 5, title: '等待生成' },
  ]
  const singleStatus = [
    { key: 0, title: '待编辑' },
    { key: 1, title: '待审核' },
    { key: -1, title: '已驳回' },
    { key: 2, title: '待生成' },
    { key: 3, title: '生成中' },
    { key: 4, title: '已生成' },
    { key: 5, title: '等待生成' },
  ]
  //列定义
  const childrenColumns = [
    {
      title: '设备',
      key: 'device',
    },
    {
      title: '单体报告名称',
      key: 'name',
    },
    {
      title: '状态',
      key: 'status',
      render: (item) => {
        return singleStatus.find((s) => s.key === item.status)?.title
      },
    },
    {
      title: '创建时间',
      key: 'creationTime',
      render: (item) => {
        return moment(item.creationTime).format('yyyy-MM-DD HH:mm:ss')
      },
    },
    {
      title: '审批时间',
      key: 'approvalDate',
      render: (item) => {
        return moment(item.approvalDate, false).format('yyyy-MM-DD HH:mm:ss')
      },
    },
    {
      title: '审批人',
      key: 'approvalUser',
      render: (item, index) => {
        const user = users.find((d) => d.id == item.approvalUser)
        return user?.name
      },
    },
  ]

  const hasPermission = (permission) => {
    return !permissions || !permission || (permission in permissions && permissions[permission])
  }

  //列定义
  const columns = [
    {
      title: '项目名称',
      key: 'project',
      render: (item, index) => {
        return item.project.name
      },
      // expand: (item, index) => {
      //   const list = item.singleReports
      //   if (list.length == 0) return <div style={{ marginLeft: '20px' }}>没有数据</div>
      //   return (
      //     <div style={{ padding: '10px 20px 10px 20px', overflow: 'visible' }}>
      //       <CommonTable
      //         otherMenu={(r, index) => singleMenu(item, r, index)}
      //         zebra={true}
      //         columns={childrenColumns}
      //         data={list}
      //         showPage={false}
      //         keyField='key'
      //       />
      //     </div>
      //   )
      // },
      //通过渲染的方式将内容渲染出来
    },
    {
      title: '变电站名称',
      key: 'station',
      render: (item, index) => {
        return item.station.name
      },
    },
    {
      title: '总体维保报告名称',
      key: 'name',
    },
    {
      title: '状态',
      key: 'status',
      render: (item) => {
        return totalStatus.find((s) => s.key === item.status)?.title
      },
    },
    {
      title: '创建时间',
      key: 'creationTime',
      render: (item) => {
        return moment(item.creationTime).format('yyyy-MM-DD HH:mm:ss')
      },
    },
    {
      title: '审批时间',
      key: 'approvalDate',
      render: (item) => {
        return moment(item.approvalDate).format('yyyy-MM-DD HH:mm:ss')
      },
    },
    {
      title: '审批人',
      key: 'approvalUser',
      render: (item, index) => {
        const user = users.find((d) => d.id == item.approvalUser)
        return user?.name
      },
    },
  ]

  //其他行菜单
  const totalMenu = (item, index) => {
    // if (item.singleReports.some((s) => s.status < 2)) {
    //   return []
    // }
    const items = [
      {
        onClick: () => {
          totalPreviewRef.current.showDialog(item)
        },
        title: t('t_detail'),
      },
    ]
    if (item.status <= 0 && item.project.managerId === userProfile.id && hasPermission('EzReport.TotalReport.Update')) {
      items.push({
        onClick: () => {
          totalEditwRef.current.showDialog(item)
        },
        title: '编辑',
      })
    }
    if (item.status > 0 && item.project.managerId === userProfile.id && hasPermission('EzReport.TotalReport.Update')) {
      items.push({
        onClick: () => {
          totalBackRef.current.showDialog(item)
        },
        title: '退回',
      })
    }
    if (item.status === 1 && item.inScope && hasPermission('EzReport.TotalReport.Approve')) {
      items.push({
        onClick: () => {
          totalApproveRef.current.showDialog(item)
        },
        title: '审批',
      })
    }
    if (item.status === 2 && item.project.managerId === userProfile.id && hasPermission('EzReport.TotalReport.Generate')) {
      items.push({
        onClick: () => {
          setTotalReportGenerate(item)
        },
        title: '生成报告',
      })
    }
    if (item.status === 4 && item.file) {
      items.push({
        onClick: () => {
          window.open(`${env.apiBase + (env.apiBase.endsWith('/') ? '' : '/')}${downloadFile()}?id=${item.file}`)
        },
        title: '下载报告',
      })
    }
    return items
  }
  const onTotalReportGenerate = () => {
    setIsTotalGenerating(true)
    axios.post(generateTotalReport(totalReportGenerate.id), {}).then(() => {
      setIsTotalGenerating(false)
      setTotalReportGenerate(null)
      setTimeout(() => refIndex.current.query(), 500)
    })
  }

  const getGetListInput = (params) => {
    return {...params, type: 'wfm' }
  }
  //返回页面
  return (
    <>
      <CommonIndex ref={refIndex} getGetListInput={getGetListInput} otherMenu={totalMenu} columns={columns} getList={getTotalReportPage} enableSorting={true} />
      <ShowTotalReport key={nanoid()} ref={totalPreviewRef} role='action' />
      <EditTotalReport key={nanoid()} ref={totalEditwRef} role='action' onSubmit={() => setTimeout(() => refIndex.current.query(), 500)} />
      <ApproveTotalReport key={nanoid()} ref={totalApproveRef} role='action' onSubmit={() => setTimeout(() => refIndex.current.query(), 500)} />
      <BackTotalReport key={nanoid()} ref={totalBackRef} role='action' onSubmit={() => setTimeout(() => refIndex.current.query(), 500)} />

      <CommonConfirm
        title={'生成报告'}
        content={'是否确定要生成总体报告？'}
        isVisible={totalReportGenerate != null}
        onConfirm={onTotalReportGenerate}
        confirmText={t('b_confirm')}
        onCancel={() => setTotalReportGenerate(null)}
        isLoading={isTotalGenerating}
      />
      <ImportTestResultFormEdit ref={formDeviceTypeRef} />
    </>
  )
}
