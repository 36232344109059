import React, { useEffect, useRef, useState } from 'react'
import { LeftPanel } from './LeftPanel'
import { Button, Checkbox, Dialog, Icon, Input, ThreeStateValue, ToggleButton, ToggleButtonGroup } from '@abb/abb-common-ux-react'
import axios from 'axios'
import { addFileCover, deleteFiles, getFilesInfo, getPath, viewFile } from 'common/apis/maindata'
import { FileSelector } from 'components/FileSelector'
import { useDispatch, useSelector } from 'react-redux'
import { getEnv } from 'slices/envSlice'
import 'file-icon-vectors/dist/file-icon-classic.min.css'
import { filesize } from 'filesize'
import { DropdownButton, DropdownButtonOption } from 'components/DropdownButton'
import { NotificationType, showNotification } from 'components/Notification/notificationSlice'
import { ResponseResult } from 'common/request'
import { useMountedState } from 'common/useMountedState'
import { DirectoryFormEdit } from './DirectoryFormEdit'
import { forEach } from 'lodash'
import { dir } from 'i18next'
import { ImageSelector } from 'components/ImageSelector'
import { tr } from 'date-fns/locale'

export const DocumentList = ({ customerId, basePath, directory, onUploaded, onDeleted, onCreatedDirectory }) => {
  const dispatch = useDispatch()
  const isMounted = useMountedState()
  const [activeDir, setActiveDir] = useState(null)
  const [fullDirectory, setFullDirectory] = useState([])
  const [allFiles, setAllFiles] = useState([])
  const [currentPathFiles, setCurrentPathFiles] = useState([])
  const [displayMode, setDisplayMode] = useState([0])
  const [selectedItem, setSelectedItem] = useState([])
  const [uploadPath, setUploadPath] = useState(null)
  const [keyword, setKeyword] = useState('')
  const [deleteConfirmId, setDeleteConfirmId] = useState([])
  const [isDeleting, setIsDeleting] = useState(false)
  const [isCovering, setIsCovering] = useState(false)
  const [createDirectoryData, setCreateDirectoryData] = useState(null)
  const [cover, setCover] = useState(null)
  const [currCover, setCurrCover] = useState(null)
  const filteredFiles = currentPathFiles.filter((f) => f.name?.indexOf(keyword) >= 0)
  const env = useSelector(getEnv)
  const formDataRef = useRef(null)
  const selectAllState =
    currentPathFiles.length > 0
      ? currentPathFiles.length === selectedItem.length
        ? ThreeStateValue.Checked
        : selectedItem.length > 0
        ? ThreeStateValue.Indeterminate
        : ThreeStateValue.Unchecked
      : ThreeStateValue.Unchecked
  const toggleSelection = (id, isSelected) => {
    if (isSelected) {
      setSelectedItem([...selectedItem, id])
    } else {
      setSelectedItem(selectedItem.filter((s) => s !== id))
    }
  }
  useEffect(() => {
    if (allFiles.length > 0 && activeDir) {
      const files = allFiles.filter((f) => f.directoryPath === activeDir.path)
      setCurrentPathFiles(files)
      setSelectedItem([])
    }
  }, [activeDir, allFiles])

  const init = () => {
    if (basePath) {
      axios.get(getPath(), { params: { path: basePath, subPath: true, removeBasePath: true, replaceId: true } }).then((resp) => {
        const datas = resp.data
        const directories = fillDirectory(directory, datas)
        setFullDirectory(directories)
        const fs = datas?.filter((d) => d.fileType === 0)
        setAllFiles(fs)
      })
    }
  }
  useEffect(() => {
    init()
  }, [basePath, directory])

  const fillDirectory = (directory, files) => {
    forEach(files, (f) => {
      if (f.directoryPath === '') {
        f.directoryPath = '/'
      }
      const dir = (f.fileType === 0 ? f.directoryPath : f.path).replace(/^\//, '')
      if (dir !== '') {
        const dirs = dir.replace(/^\//, '').split('/')
        if (dirs.length > 0) {
          dirs.unshift('/')
          fillSubDirectory(directory, dirs)
        }
      }
    })
    return directory
  }

  const fillSubDirectory = (directory, dirs, path = '') => {
    if (dirs.length <= 0) return
    var d = dirs.splice(0, 1)[0]
    const p = (path + '/' + (d === '/' ? '' : d)).replaceAll('//', '/')
    var subdir = directory.find((c) => c.path === p)
    if (!subdir) {
      subdir = { title: d, key: d, path: p, children: [] }
      directory.push(subdir)
    }
    fillSubDirectory(subdir.children, dirs, p)
  }
  const onKeywordChanged = (e) => {
    setSelectedItem([])
    setKeyword(e)
  }
  useEffect(() => {
    if (basePath && activeDir) {
      setUploadPath(`${basePath}${activeDir.path}`)
    }
  }, [basePath, activeDir])

  const onCreateDirectory = (data) => {
    init()
    onCreatedDirectory && onCreatedDirectory(data)
  }

  const onCover = () => {
    if (!currCover) {
      return
    }
    setIsCovering(true)
    axios
      .post(addFileCover(), { id: cover.id, cover: currCover })
      .then((e) => {
        dispatch(showNotification(NotificationType.Success, '添加封面成功'))
        setCover(null)
        setCurrCover(null)
        init()
      })
      .catch((e) => {
        if (!isMounted()) {
          return
        }
        dispatch(showNotification(NotificationType.Alarm, '添加封面失败'))
      })
      .then(() => {
        if (!isMounted()) {
          return
        }
        setIsCovering(false)
      })
  }

  const onDelete = (id) => {
    if (id.length === 0) {
      return
    }
    setIsDeleting(true)
    axios
      .post(deleteFiles(), { files: id })
      .then((e) => {
        dispatch(showNotification(NotificationType.Success, '删除成功'))
        setSelectedItem([])
        setDeleteConfirmId([])
        init()
        onDeleted && onDeleted()
      })
      .catch((e) => {
        if (!isMounted()) {
          return
        }
        dispatch(showNotification(NotificationType.Alarm, '删除失败'))
      })
      .then(() => {
        if (!isMounted()) {
          return
        }
        setIsDeleting(false)
      })
  }

  const onUpload=(files)=>{
    init();
    onUploaded && onUploaded(files)
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 8, height: '100%' }}>
        <div style={{ flex: 3, borderRight: 'solid 1px #999', padding: 8 }}>
          <LeftPanel
            tree={fullDirectory}
            onActive={(item) => {
              console.log(item);
              setActiveDir(item)
            }}
            onAdd={(item) => {
              console.log(item)
              setCreateDirectoryData({
                customerId: customerId,
                parentName: item.title,
                path: `${basePath}${item.path}`,
              })
              formDataRef.current.setShowSidePanel(true)
            }}
          ></LeftPanel>
        </div>
        <div className='resource-directory-main' style={{ flex: 9, flexDirection: 'column', paddingLeft: 8 }}>
          {!activeDir && <div className='central-position text-gray'>请从左侧列表选择目录</div>}
          {activeDir && (
            <>
              {/* <div className='resource-directory-controls'>
               当前目录： {JSON.stringify(activeDir)}
              </div> */}
              <div className='resource-directory-controls' style={{ display: 'flex' }}>
                <FileSelector path={uploadPath} customerId={customerId} showDownload={false} onValueChanged={onUpload} />
                {selectedItem.length > 0 && (
                  <Button sizeClass='small' text='删除' onClick={() => setDeleteConfirmId(selectedItem)} isLoading={isDeleting} />
                )}
                <div style={{ flex: 1 }}>
                  <Input type='discreet' icon='abb/search' value={keyword} onValueChange={onKeywordChanged} placeholder='在当前目录查找文件名' />
                </div>
                <div style={{ marginLeft: 16 }}>
                  <ToggleButtonGroup selected={displayMode} onChange={setDisplayMode} sizeClass='small' multiselect={false} style={{ margin: 0 }}>
                    <ToggleButton icon='abb/thumbnail-view' />
                    <ToggleButton icon='abb/list' />
                  </ToggleButtonGroup>
                </div>
              </div>

              <div className='resource-directory-details'>
                <div style={{ marginBottom: 8 }}>
                  <Checkbox
                    label={selectAllState === ThreeStateValue.Unchecked ? '全选' : `已选择 ${selectedItem.length} 个文件`}
                    disabled={filteredFiles.length === 0}
                    value={selectAllState}
                    onChange={() => setSelectedItem(selectAllState === ThreeStateValue.Checked ? [] : filteredFiles.map((f) => f.id))}
                    sizeClass='small'
                  />
                </div>
                <div className={`file-list layout-${displayMode[0] === 0 ? 'brick' : 'list'}`}>
                  {filteredFiles.length === 0 && <p className='central-position text-gray'>此目录中没有文件</p>}
                  {filteredFiles.length > 0 &&
                    filteredFiles.map((file) => {
                      const isSelected = selectedItem.indexOf(file.id) >= 0
                      return (
                        <div className={`file-item${isSelected ? ' selected' : ''}`} key={file.id}>
                          <div className='file-selector'>
                            <Checkbox sizeClass='small' value={isSelected} onChange={(e) => toggleSelection(file.id, !isSelected)} />
                          </div>
                          {displayMode[0] === 0 && (
                            <div
                              className='file-thumb'
                              onClick={(e) =>
                                window.open(`${env.apiBase + (env.apiBase.endsWith('/') ? '' : '/')}${viewFile()}?id=${file.id}${file.anchor ?? ''}`)
                              }
                            >
                              <RenderThumb file={file}></RenderThumb>
                            </div>
                          )}
                          <div
                            className='file-name'
                            style={{ cursor: 'pointer' }}
                            title={file.name}
                            onClick={(e) =>
                              window.open(`${env.apiBase + (env.apiBase.endsWith('/') ? '' : '/')}${viewFile()}?id=${file.id}${file.anchor ?? ''}`)
                            }
                          >
                            {file.name}
                          </div>
                          <div className='file-size'>{filesize(file.fileSize, { base: 2, standard: 'jedec' })}</div>
                          <div className='file-controls'>
                            <DropdownButton>
                              <DropdownButtonOption
                                onClick={() =>
                                  window.open(
                                    `${env.apiBase + (env.apiBase.endsWith('/') ? '' : '/')}${viewFile()}?id=${file.id}${file.anchor ?? ''}`
                                  )
                                }
                              >
                                打开
                              </DropdownButtonOption>
                              <DropdownButtonOption onClick={() => setCover(file)}>文件封面</DropdownButtonOption>
                              {/* <DropdownButtonOption onClick={() => {
                        setRenameFileId(file.key);
                        setInputFileName(file.name)
                        }} >重命名</DropdownButtonOption>
                      <DropdownButtonOption onClick={() => {
                        setMovingFileId([file.key]);
                        setMovingDestDir(null)
                        }} >移动到...</DropdownButtonOption>
                      <DropdownButtonOption onClick={() => setReplaceFileId(file.key)} >替换</DropdownButtonOption> */}
                              <DropdownButtonOption onClick={() => setDeleteConfirmId([file.id])}>删除</DropdownButtonOption>
                            </DropdownButton>
                          </div>
                        </div>
                      )
                    })}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <Dialog showCloseButton={false} closeOnLostFocus={true} dimBackground={true} isOpen={deleteConfirmId.length > 0} title='删除文件'>
        <div>确认删除所选文件？</div>
        <p>
          <Button text='删除' sizeClass='small' type='primary-blue' onClick={() => onDelete(deleteConfirmId)} isLoading={isDeleting} />
          <Button text='取消' sizeClass='small' style={{ marginLeft: 8 }} onClick={() => setDeleteConfirmId([])} />
        </p>
      </Dialog>

      <Dialog
        showCloseButton={true}
        closeOnLostFocus={true}
        onClose={() => {
          setCover(null)
          setCurrCover(null)
        }}
        dimBackground={true}
        isOpen={cover}
        
        title='设置文件封面'
        standardButtonsOnBottom={[
          { text: '保存', type: 'primary-blue', handler: onCover },
          {
            text: '关闭',
            type: 'primary-red',
            handler: (dlg) => {
              setCover(null)
              setCurrCover(null)
            },
          },
        ]}
      >
        <div style={{ width: 480, height: 320 }}>
          <ImageSelector
            value={cover?.cover}
            path={'/_/FileCover/'}
            onValueChanged={(d) => {
              setCurrCover(d[0].id)
            }}
          />
        </div>
      </Dialog>
      <DirectoryFormEdit ref={formDataRef} formData={createDirectoryData} onSubmit={onCreateDirectory} />
    </>
  )
}

export const RenderThumb = ({ file }) => {
  const env = useSelector(getEnv)
  const path = `${env.apiBase + (env.apiBase.endsWith('/') ? '' : '/')}${viewFile()}?id=${file.id}`
  if (file.cover) {
    const coverPath = `${env.apiBase + (env.apiBase.endsWith('/') ? '' : '/')}${viewFile()}?id=${file.cover}`
    return <img src={coverPath} draggable={false} />
  }
  switch (file.extensionName?.toLowerCase()) {
    case '.png':
    case '.jpg':
    case '.svg':
    case '.bmp':
    case '.jpeg':
      return <img src={path} draggable={false} />
    default:
      return <span style={{ fontSize: '4em' }} className={`fiv-cla fiv-icon-${file.extensionName.toLowerCase().replace('.', '')}`}></span>
  }
}
